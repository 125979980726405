import styled from 'styled-components';

import { fonts, palette } from '@/theme';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.p`
  ${fonts.text}
  padding: 15px 20px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 2px 6px;
  }
`;

export const Button = styled.button`
  height: calc(100vw / 2);
  width: 50%;
  ${fonts.large}
  &:first-child {
    border-right: 2px solid;
    border-color: ${palette.black};
  }
  @media (hover) {
    &:hover {
      box-shadow: inset 0 0 0 3px ${palette.black};
    }
  }
  @media (max-width: 991px) {
    ${fonts.text}
    &:first-child {
      border-width: 1px;
    }
  }
  @media (max-width: 991px) and (hover) {
    &:hover {
      box-shadow: inset 0 0 0 2px ${palette.black};
    }
  }
  @media (min-width: 1441px) {
    height: 720px;
  }
`;
