import React, { useCallback } from 'react';
import { keys, pipe, pathOr, find, propEq, ifElse, path } from 'ramda';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { useObserver } from '@/hooks';
import { KIND, MEMBERSHIP, PUBLISH, PAYMENT, AMOUNT, CURRENCY, PAYMENT_METHOD, INVOICE } from '@/server-api/consts';
import { FullScreenWrapper } from '../styles';

import { Container, Title, Button } from './styles';

const Step0 = ({ pageContext }) => {
  const { title, kinds } = pageContext;
  const { setPosition } = useObserver();

  const handleClick = useCallback(
    ({ currentTarget }) => {
      const { value } = currentTarget.dataset;

      if (value) {
        navigate('/goenner/schritt-1/', {
          state: {
            [MEMBERSHIP]: 'gold',
            [PUBLISH]: true,
            [PAYMENT]: {
              [AMOUNT]: pipe(
                pathOr([], [value, 'memberships']),
                find(propEq('key', 'gold')),
                ifElse(Boolean, path(['values', 0]), () => 200100)
              )(kinds),
              [CURRENCY]: 'CHF',
              [PAYMENT_METHOD]: INVOICE,
            },
            [KIND]: value,
          },
        });
      }
    },
    [kinds]
  );

  return (
    <Container>
      <Title onTouchStart={setPosition} onMouseEnter={setPosition}>
        {title}
      </Title>
      <FullScreenWrapper onTouchStart={setPosition} onMouseEnter={setPosition}>
        {keys(kinds).map((key) => (
          <Button key={key} data-value={key} type="button" onClick={handleClick}>
            {kinds[key].name}
          </Button>
        ))}
      </FullScreenWrapper>
    </Container>
  );
};

Step0.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    kinds: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default Step0;
export { default as Head } from '@/components/Head';
